import axios from './index';
const baseUrl = process.env.NODE_ENV === 'production'? '/api' : '/api';

const API = {
  // 获取图片验证码
  GetImageVerifyCode: () => axios.download(baseUrl + '/imageverifycode/getImageVerifyCode', { rad: Date.now() }),

  // 用户协议
  UserAgreement: (data = {}, formData) => axios.post(baseUrl + '/portal/basic/basicAgreement/selectAgreement', data, formData, true),
  LoginQRCode: (data = {}, formData) => axios.post(baseUrl + '/portal/users/userFront/getQRCode', data, formData, true),
  WaitPollLogin: (data = {}, formData) => axios.post(baseUrl + '/portal/users/userFront/pollLogin', data, 3, true),
  EditForntUser: (data = {}, formData) => axios.post(baseUrl + '/portal/users/userFront/updateUserFront', data, formData, true),
  ForntUserDetail: (data = {}, formData) => axios.post(baseUrl + '/portal/users/userFront/detailUserFront', data, formData, true),
  LoginOut: (data = {}, formData) => axios.post(baseUrl + '/portal/users/userFront/logout', data, formData, true),

  // 附件管理
  UploadFile: (data) => axios.post(baseUrl + '/attachment/upload', data, 2),
  DownloadFile: (data) => axios.download(baseUrl + '/attachment/download', data),

  // 轮播图
  CarouselList: (data = {}, formData) => axios.post(baseUrl + '/portal/basic/basicCarousel/selectAllCarousel', data, formData, true),

  // 标委会
  StandardCommittee: (data = {}, formData) => axios.post(baseUrl + '/portal/basic/basicCouncil/selectCouncil', data, formData, true),
  StandardCommitteeTable: (data = {}, formData) => axios.post(baseUrl + '/portal/basic/basicMember/selectAllMember', data, formData, true),

  // 培训课程
  // 课程
  TrainingCoursesList: (data, formData) => axios.post(baseUrl + '/portal/course/course/pageCourse', data, formData, true),
  TrainingCoursesDetail: (data, formData) => axios.post(baseUrl + '/portal/course/course/detailCourse', data, 3, true),
  // 机构
  InstitutionList: (data, formData) => axios.post(baseUrl + '/portal/course/courseOrganization/pageOrganization', data, formData, true),
  InstitutionDetail: (data, formData) => axios.post(baseUrl + '/portal/course/courseOrganization/detailOrganization', data, formData, true),

  // 讲师
  TeacherDetail: (data, formData) => axios.post(baseUrl + '	/portal/course/courseTeacher/detailTeacher', data, 3, true),

  // 文章
  ArticleList: (data, formData) => axios.post(baseUrl + '/portal/article/selectArticle', data, formData, true),
  ArticleTotalList: (data, formData) => axios.post(baseUrl + '/portal/article/selectTraffic', data, formData, true),
  HotArticleList: (data = {}, formData) => axios.post(baseUrl + '/portal/article/trafficSort', data, formData, true),
  ArticleTypeList: (data, formData) => axios.post(baseUrl + '/portal/article_classification/listByParent', data, formData, true),
  ArticleDetail: (data, formData) => axios.post(baseUrl + '/portal/article/selectDetailArticle', data, formData, true),
  CancelArticle: (data, formData) => axios.post(baseUrl + '/portal/article/cancelCollectionArticle', data, formData, true),
  CollectArticle: (data, formData) => axios.post(baseUrl + '/portal/article/collectionArticle', data, formData, true),
  CollectArticleList: (data, formData) => axios.post(baseUrl + '/portal/article/selectCollectionArticle', data, formData, true),
  DownArticleDetail: (data, formData) => axios.post(baseUrl + '/portal/article/selectDownDetailArticle', data, formData, true),


  // 标准管理
  TreeList: (data = {}, formData) => axios.post(baseUrl + '/portal/standard/standardCategory/selectAllStandardCategory', data, formData, true),
  LabelList: (data, formData) => axios.post(baseUrl + '/portal/standard/standardLabel/selectLabels', data, formData, true),
  StandardList: (data, formData) => axios.post(baseUrl + '/portal/standard/standard/selectPortalStandardByCondition', data, formData, true),
  StandardDetail: (data, formData) => axios.post(baseUrl + '	/portal/standard/standard/selectDetailsStandard', data, formData, true),
}
export default API
