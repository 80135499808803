import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { routes } from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  mode: 'history',
  base: process.env.NODE_ENV === "production" ? "../" : "/",
  routes
})

export default router
