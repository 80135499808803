import axios from 'axios';
import qs from 'qs';
import API from './api';

let http = {
  post: '',
  get: '', 
  download: ''
}

function isBoolean(v) {
  return typeof v === 'boolean';
}

/*
 * api      : url,
 * data     : 参数,
 * formData : 规定参数格式；
 *            不传使用JSON格式（默认）；
 *            传1使用formdata格式；
 *            传2使用formdata格式为上传文档、图片等；
 *            传3则把参数拼接到url后。
 *            传4是数组格式
 * filter   : 布尔值，true则过滤空字符串
 * opt      : 请求配置
 */
http.post = (api, data, formData, filter, opt) => {
  let resData = {};
  let params = qs.stringify(data);

  if(isBoolean(formData)) {
    filter = formData;
    formData = undefined;
  }
  api = formData == 3 ? api + '?' + params : api;
  if(filter && formData != 4) {
    Object.keys(data).forEach(key => {
      if(data[key] !== '') {
        resData[key] = data[key];
      }
    });
  } else {
    resData = data;
  }
  let options = formData == 3 || formData == 4 ? {
    headers: {
      'Content-Type': 'application/json'
    }
  } : {
    headers: {
      'Content-Type': formData ? 'application/x-www-form-urlencoded; charset=UTF-8' : 'application/json'
    },
    transformRequest:  formData ? [function (resData) {
      let ret = '';
      if(formData == 2) {
        return resData;
      } else {
        for (let it in resData) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(resData[it]) + '&';
        }
        return ret;
      }

    }] : [function(resData) {
      return JSON.stringify(resData);
    }],
  }
  if(opt) {
    options = Object.assign(options, opt);
  }
  return new Promise((resolve, reject) => {
    axios.post(api, formData == 2 ? resData : formData == 3 ? null : resData, options).then((res) => {
      resolve(res);
    }).catch((res) => {
      reject(res);
    })
  })
}

http.get = (api, params) => {
  return new Promise((resolve, reject) => {
    axios.get(api, {
      params: params,
    }).then((res) => {
      resolve(res);
    }).catch((res) => {
      reject(res);
    })
  })
}

http.download = (api, data) => {
  let params = '';
  if(data) {
    params = '?' + qs.stringify(data);
  }
  return api + params;
}

export default http;
