export const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: () => import('@/layout/Index'),
    children: [
      { // 首页
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/Index'),
        meta: {
          titleName: '广东省碳达峰碳中和标准化技术信息公共服务平台'
        }
      },
      { // 文章确认
        path: '/checkLine/:id',
        name: 'checkLine',
        component: () => import('@/views/article/Detail'),
        meta: {
          title: '文章预览',
          url: '',
          index: 3
        }
      },
      { // 政策法规
        path: '/policiesAndRegulations/:type?',
        name: 'policiesAndRegulations',
        component: () => import('@/views/article/Index'),
        meta: {
          title: '政策法规',
          detail: '/policiesAndRegulationsDetail',
          index: 0,
          titleName: '政策法规 - 广东省碳达峰碳中和标准化技术信息公共服务平台'
        }
      },
      { // 政策法规
        path: '/policiesAndRegulationsDetail/:id',
        name: 'policiesAndRegulationsDetail',
        component: () => import('@/views/article/Detail'),
        meta: {
          title: '政策法规',
          url: '/policiesAndRegulations',
          index: 0
        }
      },
      { // 标准研制
        path: '/standardDevelopment/:type?',
        name: 'standardDevelopment',
        component: () => import('@/views/article/Index'),
        meta: {
          title: '标准研制全生命周期信息呈现',
          icon: 'standard',
          needType: 'standardDevelopment',
          detail: '/standardDevelopmentDetail',
          index: 1,
          titleName: '标准研制全生命周期信息呈现 - 广东省碳达峰碳中和标准化技术信息公共服务平台'
        }
      },
      { // 标准研制
        path: '/standardDevelopmentDetail/:id',
        name: 'standardDevelopmentDetail',
        component: () => import('@/views/article/Detail'),
        meta: {
          title: '标准研制',
          url: '/standardDevelopment',
          index: 1
        }
      },
      { // 标准信息
        path: '/standardInformationBase/:type?',
        name: 'standardInformationBase',
        component: () => import('@/views/standardInformationBase/Index'),
        meta: {
          titleName: '标准信息库 - 广东省碳达峰碳中和标准化技术信息公共服务平台'
        }
      },
      { // 标准信息
        path: '/labelDetail/:id',
        name: 'labelDetail',
        component: () => import('@/views/standardInformationBase/LabelDetail'),
        meta: {
          url: '/standardInformationBase'
        }
      },
      { // 标准信息
        path: '/standardDetail',
        name: 'standardDetail',
        component: () => import('@/views/standardInformationBase/Detail'),
        meta: {
          title: '标准信息库',
          url: '/standardInformationBase'
        }
      },
     
      { // 双碳示范
        path: '/doubleCDemonstration/:type?',
        name: 'doubleCDemonstration',
        component: () => import('@/views/article/Index'),
        meta: {
          title: '双碳技术示范案例',
          icon: 'profile-line',
          needType: 'doubleCDemonstration',
          detail: '/doubleCDemonstrationDetail',
          index: 2,
          titleName: '双碳技术示范案例 - 广东省碳达峰碳中和标准化技术信息公共服务平台'
        }
      },
      { // 双碳示范
        path: '/doubleCDemonstrationDetail/:id',
        name: 'doubleCDemonstrationDetail',
        component: () => import('@/views/article/Detail'),
        meta: {
          title: '双碳示范',
          url: '/doubleCDemonstration',
          index: 2
        }
      },
      { // 课程培训
        path: '/trainingCourses',
        name: 'trainingCourses',
        component: () => import('@/views/trainingCourses/Index'),
        meta: {
          title: '培训课程',
          icon: 'training',
          place: '搜索课程标题或机构名称',
          needType: 'trainingCourses',
          titleName: '培训课程 - 广东省碳达峰碳中和标准化技术信息公共服务平台'
        }
      },
      { // 课程培训详情页
        path: '/trainingCoursesDetail/:id',
        name: 'trainingCoursesDetail',
        component: () => import('@/views/trainingCourses/Detail'),
        meta: {
          title: ['培训课程', '课程详情'],
          url: '/trainingCourses',
        }
      },
      { // 机构详情页
        path: '/institutionDetail/:id',
        name: 'institutionDetail',
        component: () => import('@/views/trainingCourses/SecondDetail'),
        meta: {
          title: ['培训课程', '机构详情'],
          url: '/trainingCourses',
        }
      },
      { // 讲师详情页
        path: '/teacherDetail/:id',
        name: 'teacherDetail',
        component: () => import('@/views/trainingCourses/SecondDetail'),
        meta: {
          title: ['培训课程', '讲师详情'],
          url: '/trainingCourses',
        }
      },
      { // 标委会
        path: '/standardCommittee',
        name: 'standardCommittee',
        component: () => import('@/views/StandardCommittee'),
        meta: {
          titleName: '标委会介绍 - 广东省碳达峰碳中和标准化技术信息公共服务平台'
        }
      },
      { // 个人中心
        path: '/personCenter/:type?',
        name: 'personCenter',
        component: () => import('@/views/personCenter/Index'),
      },
      { // 用户协议
        path: '/userAgreement',
        name: 'userAgreement',
        component: () => import('@/views/UserAgreement'),
      },
      { // 链接失效
        path: '/403',
        name: '403',
        component: () => import('@/views/LinkInvalid'),
      },
      { // 投稿页面
        path: '/tougao',
        name: 'tougao',
        component: () => import('@/views/Tougao'),
      },
      { // 404
        path: '/:catchAll(.*)',
        name: '404',
        component: () => import('@/views/404'),
      },
    ]
  },
  {
    path: '/browser',
    name: 'browser',
    component: () => import('@/layout/Browser')
  },
  {
    path: '/showPdf',
    name: 'showPdf',
    component: () => import('@/views/standardInformationBase/ShowPdf'),
  },
]
