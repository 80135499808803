import { createStore } from 'vuex'

export default createStore({
  state: {
    articleClassificationId: null,
    loginIn: 0,
    open: 0,
    userInfo: null
  },
  mutations: {
    article_type(state, data) {
      state.articleClassificationId = data
    },
    login_type(state, data) {
      state.loginIn = data
    },
    open_type(state, data) {
      state.open = data
    }, 
    userInfo_type(state, data) {
      state. userInfo = { ...data }
    },
  },
  actions: {
  },
  modules: {
  }
})
