import 'babel-polyfill';
import { createApp, onMounted } from 'vue'
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import App from './App.vue';
import '@arco-design/web-vue/dist/arco.css';
import '@arco-themes/vue-doublec/css/arco.css';
import router from './router'
import store from './store'
// import './icons/index'
import SvgIcon from '@/components/SvgIcon.vue'
import axios from 'axios';
import API from './require/api';
import VueWechatTitle from 'vue-wechat-title'//动态修改title

 
const req = require.context('./icons/svg', false,  /\.svg$/)
req.keys().map(req)
axios.interceptors.response.use(response => {
  return response;
}, error => {
  if(error.response.status === 401 || error.response.status === 403) { //与后台约定登录失效的返回码
    router.replace('/');
  }
})
App.setup = () => {
  onMounted(() => {
    document.dispatchEvent(new Event('render-event'));
  })
}
const app = createApp(App);
app.config.globalProperties.$API = API;
app.config.globalProperties.baseUrl = '/api';
app.use(VueWechatTitle)
app.use(store);
app.use(router);
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.component('svg-icon', SvgIcon);
app.mount('#app');
